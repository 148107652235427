<script setup lang="ts">
import { FirebaseError } from 'firebase/app'
import { VueFinalModal } from 'vue-final-modal'
import { firebaseErrors } from '~/types/FirebaseError'

const emits = defineEmits(['close', 'register'])

const { hasUserPassword, signIn } = useAuth()

const { showAlert } = useAlert()

const email = ref<string>('')
const password = ref<string>('')

const showPasswordInput = ref<boolean>(false)

const linkSent = ref<boolean>(false)

const loading = ref<boolean>(false)

async function login () {
  try {
    loading.value = true
    await signIn({ email: email.value, password: password.value })
    emits('close')
  }
  catch (e) {
    if (e instanceof FirebaseError) {
      showAlert({
        intent: 'danger',
        title: firebaseErrors[e.code].title,
        content: firebaseErrors[e.code].message,
        seconds: 5,
      })
    }
  }
  loading.value = false
}

async function checkPassword () {
  loading.value = true
  const { hasPassword, exists } = await hasUserPassword(email.value)
  if (hasPassword) {
    showPasswordInput.value = true
  }
  else if (exists) {
    linkSent.value = true
  }
  else {
    showAlert({
      title: 'Nenalezeno',
      content: 'Účet se zadaným emailem neexistuje',
      intent: 'danger',
      seconds: 5,
    })
  }
  loading.value = false
}
</script>

<template>
  <VueFinalModal class="flex flex-col justify-center items-center" content-class="bg-white p-32px rounded-12px w-384px">
    <form v-if="!linkSent" v-auto-animate @submit.prevent="" @keydown.enter="showPasswordInput ? login() : checkPassword()">
      <div flex items-center justify-between text="24px black">
        <h1 font-700 leading-24px text-24px>
          Přihlásit se
        </h1>
        <UnoIcon i-heroicons-solid-x cursor-pointer @click="emits('close')" />
      </div>
      <p text="14px app-text-black" font-400 leading-20px mt-8px max-w-264px whitespace-normal>
        Vytvořte nový účet a jednoduše spravujte své objednávky.
      </p>
      <KInput
        v-model="email" type="email" name="email" label="Váš e-mail" placeholder="Zadejte váš e-mail" mt-24px focus-on-load
        w-full
      />
      <KInput
        v-if="showPasswordInput" v-model="password" type="password" name="passowrd" label="Heslo"
        placeholder="Zadejte heslo" mt-16px focus-on-load
      />
      <button
        :class="{ 'app-btn-loading': loading }" :disabled="loading" type="button" app-btn uppercase w-full mt-16px
        flex justify-center items-center @keyup.enter="showPasswordInput ? login() : checkPassword()"
        @click="showPasswordInput ? login() : checkPassword()"
      >
        <UnoIcon v-if="loading" text="20px" i-mdi-loading animate-spin />
        <p v-else>
          Pokračovat
        </p>
      </button>
      <div text-14px font-400 leading-20px mt-24px>
        <span text-app-text-black>Nemáte účet?</span>
        <button type="button" text-app-blue ml-4px cursor-pointer @click="emits('register')">
          Zaregistrujte se
        </button>
      </div>
    </form>

    <div v-else flex flex-col items-center gap-8px>
      <UnoIcon i-heroicons-solid-check-circle text="app-green 64px" />
      <h1 text-20px font-700 text-black>
        Odkaz vám byl odeslán na email
      </h1>
      <p text-14px font-500 text-center whitespace-normal>
        Nyní se můžete přihlásit přes odkaz v emailu.
      </p>
      <p text-app-red font-600 mt-4px whitespace-pre-wrap text-center>
        Pokud vám e-mail nedorazí do 2 minut, zkontrolujte složku SPAM, případně Nevyžádaná pošta.
      </p>
    </div>
  </VueFinalModal>
</template>
