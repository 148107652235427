export class Dimensions {
  constructor (
    public d: number,
    public h: number,
    public w: number,
  ) {}

  static fromJson (json: Record<string, any>): Dimensions {
    return new Dimensions(
      json.d,
      json.h,
      json.w,
    )
  }

  public toJson (): Record<string, any> {
    return {
      d: this.d,
      h: this.h,
      w: this.w,
    }
  }
}
