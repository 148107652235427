<script lang="ts" setup>
const props = defineProps<{
  label?: string
  name: string
  placeholder?: string
  modelValue: string | number | Date | undefined
  type: string
  required?: boolean
  focusOnLoad?: boolean
  suffix?: string
  suffixSize?: string
  readonly?: boolean
  inline?: boolean
}>()

const emits = defineEmits(['update:modelValue', 'onBlur', 'onFocus'])

const value = useVModel(props, 'modelValue', emits)

const inputRef = ref<HTMLInputElement>()

function onLoad () {
  if (props.focusOnLoad)
    inputRef.value?.focus()
}

function focusIfDateType () {
  if (props.type === 'date')
    inputRef.value?.showPicker()
}
</script>

<template>
  <div flex :class="[inline ? 'flex-row items-center justify-between gap-16px' : 'flex-col items-start gap-8px']" relative @click="focusIfDateType()">
    <div v-if="label || required" flex items-center gap-6px text-14px leading-16px font-400>
      <label v-if="label" text-black :for="name">{{ label }}</label>
      <p v-if="required" text-app-red text-center>
        *
      </p>
    </div>
    <input
      ref="inputRef" v-model="value" :onload="onLoad()" :type="type" :name="name" :placeholder="placeholder" bg-white text="14px black" font-400 leading-16px rounded-full
      border="1px solid app-stroke" px-16px py-12px w-full :readonly="readonly" :class="{ 'text-app-text-dark!': readonly, 'max-w-200px': inline }" @focus="emits('onFocus')" @blur="emits('onBlur')"
    >
    <div v-if="suffix" :class="[suffixSize ? `text-${suffixSize}px` : 'text-18px']" absolute right-0 h-42px flex items-center justify-center rounded-r-full bg-app-bg-gray w-40px border border-l-none>
      {{ suffix }}
    </div>
  </div>
</template>
