import type { Company } from '~/types/Company'
import type { User } from '~/types/User'

export const useCompany = defineStore('company', () => {
  const { isEmailRegistered } = useAuth()

  async function createCompany (companyData: Company, users: User[]) {
    const { data: company } = await useAsyncData('createCompany', () => $fetch('/api/company/create', { method: 'POST', body: companyData.toJson() }))
    if (company.value)
      await updateCompanyUsers(company.value.id, users)
  }

  async function updateCompanyUsers (idCompany: string, users: User[]) {
    const validUsers: User[] = []

    try {
      for (const user of users) {
        if (!user.email)
          continue

        if (!user.id && ((await isEmailRegistered(user.email)).isRegistered))
          return console.error(`Email: ${user.email} is already registered`)

        validUsers.push(user)
      }

      await useAsyncData('createCompanyUsers', () => $fetch('/api/company/users/create-company-users', {
        method: 'POST',
        body: {
          idCompany,
          users: validUsers,
        },
      }))
    }
    catch (e) {
      console.error(e)
    }
  }

  return {
    createCompany,
    updateCompanyUsers,
  }
})
