<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'

const emits = defineEmits(['close', 'setPassword'])

const { updateUserPassword } = useAuth()

const password = ref<string>('')

const loading = ref<boolean>(false)

async function setPassword () {
  loading.value = true
  await updateUserPassword(password.value)
  emits('setPassword')
  loading.value = false
}
</script>

<template>
  <VueFinalModal class="flex flex-col justify-center items-center" content-class="bg-white p-32px rounded-12px w-384px">
    <div v-auto-animate>
      <div flex items-center justify-between text="24px black">
        <h1 font-700 leading-24px text-24px>
          Nastavení hesla
        </h1>
        <UnoIcon i-heroicons-solid-x cursor-pointer @click="emits('close')" />
      </div>
      <p text="14px app-text-black" font-400 leading-20px mt-8px max-w-264px whitespace-normal>
        Nastavte si heslo pro budoucí přihlašování
      </p>
      <KInput
        v-model="password" type="password" name="password" label="Heslo" placeholder="Zadejte heslo" mt-24px
        w-full
      />
      <button :disabled="loading" :class="{ 'app-btn-loading': loading }" type="button" app-btn uppercase w-full mt-16px flex justify-center items-center @keyup.enter="setPassword()" @click="setPassword()">
        <UnoIcon v-if="loading" text="20px" i-mdi-loading animate-spin />
        <p v-else>
          Nastavit
        </p>
      </button>
    </div>
  </VueFinalModal>
</template>
