import { type User as FirebaseUser, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendSignInLinkToEmail, signInWithEmailAndPassword, signInWithEmailLink, signOut, updatePassword } from 'firebase/auth'

export const useAuth = defineStore('auth', () => {
  const firebaseUser = useCurrentUser()

  const auth = useFirebaseAuth()!

  const { user } = storeToRefs(useUser())

  const router = useRouter()

  async function signUp (credentials: { email: string, password: string }) {
    const { email, password } = credentials

    const { user: signedUser } = await createUserWithEmailAndPassword(auth, email, password)

    resolveSignedUser(signedUser)
  }

  async function signIn (credentials: { email: string, password?: string, link?: string }) {
    const { email, password } = credentials

    let signedUser

    if (credentials.link)
      signedUser = (await signInWithEmailLink(auth, credentials.email, credentials.link)).user

    else
      signedUser = (await signInWithEmailAndPassword(auth, email, password ?? '')).user

    await resolveSignedUser(signedUser)
  }

  async function logout () {
    try {
      signOut(auth)
    }
    catch (error) {
      console.error(error)
      router.replace('/')
    }
  }

  async function resolveSignedUser (signedUser: FirebaseUser) {
    firebaseUser.value = signedUser

    try {
      const { fetchUserData } = useUser()
      await fetchUserData(firebaseUser.value!.uid)
      router.replace('/')
    }
    catch (error) {
      console.error(error)
    }
  }

  async function hasUserPassword (email: string) {
    const { hasPassword, isRegistered } = await isEmailRegistered(email)

    if (!isRegistered) {
      return {
        hasPassword: false,
        exists: false,
      }
    }

    if (hasPassword) {
      return {
        hasPassword: true,
        exists: true,
      }
    }

    const origin = useRequestURL().origin

    const url = `${origin}?sign-email-link=${email}`

    try {
      await sendSignInLinkToEmail(auth, email, {
        url,
        handleCodeInApp: true,
      })
    }
    catch (error) {
      console.error(error)
    }
    return {
      hasPassword: false,
      exists: true,
    }
  }

  async function updateUserPassword (password: string) {
    try {
      await updatePassword(firebaseUser.value!, password)
      await logout()
    }
    catch (error) {
      console.error(error)
    }
  }

  async function isEmailRegistered (email: string) {
    const isRegistered = await $fetch('/api/auth/user-exists', { method: 'POST', body: { email } })
    const hasPassword = (await fetchSignInMethodsForEmail(auth, email)).includes('password')
    return {
      isRegistered,
      hasPassword,
    }
  }

  auth.onAuthStateChanged(async fbUser => {
    if (fbUser) {
      firebaseUser.value = fbUser
      if (!user.value)
        resolveSignedUser(fbUser)
      return
    }
    firebaseUser.value = null
    user.value = null
    router.replace('/')
  })

  return {
    firebaseUser,
    hasUserPassword,
    updateUserPassword,
    isEmailRegistered,
    signUp,
    signIn,
    logout,
  }
})
