export enum UserRole {
  admin = 'admin',
  customer = 'customer',
}

export class User {
  constructor (
    public id: string,
    public uid: string,
    public name: string,
    public surname: string,
    public email: string,
    public roles: UserRole[],
    public idCompany: string,
    public hasPassword: boolean,
    public phone: string,
  ) { }

  public get inicials (): string {
    return `${this.name.substring(0, 1)}${this.surname.substring(0, 1)}`
  }

  public get fullName (): string {
    return `${this.name} ${this.surname}`
  }

  public get isAdmin (): boolean {
    return this.roles.includes(UserRole.admin)
  }

  static newUser (): User {
    return new User(
      '',
      '',
      '',
      '',
      '',
      [UserRole.customer],
      '',
      false,
      '',
    )
  }

  static fromJson (json: Record<string, any>): User {
    return new User(
      json.id,
      json.uid,
      json.name,
      json.surname,
      json.email,
      json.roles,
      json.id_company,
      json.has_password,
      json.phone,
    )
  }

  public toJson (): Record<string, any> {
    return {
      id: this.id,
      uid: this.uid,
      name: this.name,
      surname: this.surname,
      email: this.email,
      roles: this.roles,
      id_company: this.idCompany,
      has_password: this.hasPassword,
      phone: this.phone,
    }
  }
}
