export enum SaleType {
  ventilations = 'ventilations',
  cableHoles = 'cableHoles',
  panel = 'panel',
  grid = 'grid',
  sr30 = 'sr30',
  sr45 = 'sr45',
  sr60 = 'sr60',
  sr90 = 'sr90',
  sp30 = 'sp30',
  sp45 = 'sp45',
  sp60 = 'sp60',
  sz30 = 'sz30',
  sz45 = 'sz45',
  sz60 = 'sz60',
  szl30 = 'szl30',
  szl45 = 'szl45',
  szl60 = 'szl60',
}

export class Sale {
  public from: Date
  public to?: Date

  constructor (
    public type: SaleType,
    public sale: number,
    public enabled: boolean,
    from?: Date,
    to?: Date,
  ) {
    this.enabled ??= true
    this.from = from ??= new Date()
    this.to = to
  }

  static fromJson (json: Record<string, any>): Sale {
    return new Sale(
      json.type,
      json.sale,
      json.enabled,
      json.from,
      json.to,
    )
  }

  public toJson (): Record<string, any> {
    return {
      type: this.type,
      sale: this.sale,
      from: this.from,
      to: this.to,
      enabled: this.enabled,
    }
  }

  public setFromDate (value: string) {
    this.from = new Date(value)
  }

  public setToDate (value: string) {
    this.to = new Date(value)
  }
}
export const typeOptions: { label: string, value: string }[] = [
  { value: SaleType.ventilations, label: 'Ventilace' },
  { value: SaleType.cableHoles, label: 'Ucpávky' },
  { value: SaleType.grid, label: 'Přistrojový rošt, masky, el. příprava' },
  { value: SaleType.panel, label: 'Montážní panel' },
  { value: SaleType.sr30, label: 'SR EI 30' },
  { value: SaleType.sr45, label: 'SR EI 45' },
  { value: SaleType.sr60, label: 'SR EI 60' },
  { value: SaleType.sr90, label: 'SR EI 90' },
  { value: SaleType.sp30, label: 'SP EI 30' },
  { value: SaleType.sp45, label: 'SP EI 45' },
  { value: SaleType.sp60, label: 'SP EI 60' },
  { value: SaleType.sz30, label: 'SZ EI 30' },
  { value: SaleType.sz45, label: 'SZ EI 45' },
  { value: SaleType.sz60, label: 'SZ EI 60' },
  { value: SaleType.szl30, label: 'SZ-L EI 30' },
  { value: SaleType.szl45, label: 'SZ-L EI 45' },
  { value: SaleType.szl60, label: 'SZ-L EI 60' },
]
