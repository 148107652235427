import moment from 'moment'

export function formatPrice (price: number) {
  return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replaceAll('.', ',')
}

export function formatDate (date: Date, options: { time?: boolean } = {}) {
  return moment(date).format(options.time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')
}

export function formatInputDate (date: Date): string {
  return moment(date).format('mm/dd/yyyy')
}
