import { CartItem } from './CartItem'
import type { CaseConfig } from './CaseConfig'

export class Cart {
  constructor (
    public items: CartItem[],
    public savedDate: Date,
  ) { }

  static fromJson (json: Record<string, any>): Cart {
    return new Cart(json.items.map((js: Record<string, any>) => CartItem.fromJson(js)), json.saved_date)
  }

  public toJson (): Record<string, any> {
    return {
      items: this.items.map(i => i.toJson()),
      saved_date: this.savedDate,
    }
  }

  public add (item: CartItem): void {
    this.items.push(item)
  }

  public addCaseConfig (cc: CaseConfig): void {
    const duplicate = this.items.find(item => item.caseConfig.id === cc.id)
    if (!duplicate)
      this.items.push(new CartItem(1, cc, new Date()))
    else
      duplicate.quantity++
  }

  public removeItem (item: CartItem): void {
    this.items.splice(this.items.indexOf(item), 1)
  }

  public removeCaseConfigById (id: string): void {
    this.items = this.items.filter(item => item.caseConfig.id !== id)
  }

  public clear (): void {
    this.items = []
  }

  public get sum (): CartSum {
    const cartSum = new CartSum(
      this.isNotEmpty ? this.items.map(c => c.sum).reduce((prev, curr) => prev + curr) : 0,
    )

    cartSum.vat = +(cartSum.cost * 0.21).toFixed(2)
    cartSum.total = +(cartSum.cost + cartSum.vat).toFixed(2)
    return cartSum
  }

  public get isEmpty (): boolean {
    return this.items.length === 0
  }

  public get isNotEmpty (): boolean {
    return this.items.length > 0
  }

  public get isCustomDimensionsCases (): boolean {
    return this.items.filter(i => i.caseConfig.isCustomDimensions).length > 0
  }
}

export class CartSum {
  constructor (
    public cost: number,
    public vat?: number,
    public total?: number,
  ) {}
}
