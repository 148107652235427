export class ElectricityCover {
  constructor (
    public label: string,
    public height: number,
    public moduleCount: number,
  ) {}

  static fromJson (json: Record<string, any>): ElectricityCover {
    return new ElectricityCover(
      json.label,
      json.height,
      json.module_count,
    )
  }

  public toJson (): Record<string, any> {
    return {
      label: this.label,
      height: this.height,
      module_count: this.moduleCount,
    }
  }

  public get moduleCountList (): number[] {
    return Array.from({ length: this.moduleCount }, (_, i) => i)
  }

  public get modulesWidth (): number {
    return this.moduleCount * 220
  }
}
