import type { CaseConfig } from '~/types/CaseConfig'

export interface TabData {
  label: string
  config: CaseConfig
}

export const useTabs = defineStore('tabs', () => {
  const tabs = useState<TabData[]>(() => [])

  const activeTabIndex = ref<number>(0)

  const createTab = (caseConfig: CaseConfig) => {
    tabs.value.push({ label: 'Nová karta', config: caseConfig })
    activeTabIndex.value = tabs.value.length - 1
  }

  const removeTab = (configId: string) => {
    const removedTabIndex = tabs.value.indexOf(tabs.value.find(tab => tab.config.id === configId)!)
    tabs.value.splice(removedTabIndex, 1)

    if (removedTabIndex < activeTabIndex.value || removedTabIndex === activeTabIndex.value)
      activeTabIndex.value--
    if (tabs.value.length === 1)
      activeTabIndex.value = 0
  }

  const updateTabLabel = (index: number, newLabel: string) => {
    if (tabs.value[index])
      tabs.value[index].label = newLabel
  }

  const initTabs = (configs: CaseConfig[]) => {
    for (const config of configs)
      tabs.value.push({ label: config.caseLabel.split('-')[0] || 'Nová karta', config })
  }

  return {
    tabs,
    activeTabIndex,
    initTabs,
    createTab,
    removeTab,
    updateTabLabel,
  }
})
