import { Company, CompanyAddress } from './Company'
import { OrderItem } from './OrderItem'

export class Order {
  constructor (
    public id?: string,
    public idCompany?: string,
    public company?: Company,
    public deliveryAddress?: CompanyAddress,
    public code?: string,
    public itemCount?: number,
    public dateCreated?: Date,
    public fiscalYear?: number,
    public datePurchased?: Date,
    public userCreated?: string,
    public userPurchased?: string,
    public deliveryPrice?: number,
    public deliveryKm?: number,
    public items?: OrderItem[],
    public withDelivery?: boolean,
    public note?: string,
    public contactEmail?: string,
    public contactPhone?: string,
    public contactName?: string,
  ) {
    this.items ??= []
    this.withDelivery ??= true
    this.deliveryAddress ??= new CompanyAddress()
    this.deliveryPrice ??= 0
    this.deliveryKm ??= 0
  }

  static fromJson (json: Record<string, any>): Order {
    return new Order(
      json.id,
      json.id_company,
      json.company ? Company.fromJson(json.company) : undefined,
      json.delivery_address ? CompanyAddress.fromJson(json.delivery_address) : undefined,
      json.code,
      json.item_count,
      new Date(json.date_created),
      json.fiscal_year,
      new Date(json.date_purchased),
      json.user_created,
      json.user_purchased,
      json.delivery_price,
      json.delivery_km,
      (json.items ?? []).map((itemJson: Record<string, any>) => OrderItem.fromJson(itemJson)),
      json.with_delivery,
      json.note,
      json.contact_email,
      json.contact_phone,
      json.contact_name,
    )
  }

  toJson (): Record<string, any> {
    return {
      id: this.id,
      id_company: this.idCompany,
      company: this.company?.toJson(),
      delivery_address: this.deliveryAddress?.toJson(),
      code: this.code,
      item_count: this.itemCount,
      date_created: this.dateCreated?.toISOString(),
      fiscal_year: this.fiscalYear,
      date_purchased: this.datePurchased?.toISOString(),
      user_created: this.userCreated,
      user_purchased: this.userPurchased,
      delivery_price: this.deliveryPrice,
      delivery_km: this.deliveryKm,
      items: this.items?.map(item => item.toJson()),
      with_delivery: this.withDelivery,
      note: this.note,
      contact_email: this.contactEmail,
      contact_phone: this.contactPhone,
      contact_name: this.contactName,
    }
  }

  public get itemsString (): string {
    return this.items!.map(e => `${e.quantity}x ${e.caseConfig.caseLabel}`).join(', ')
  }

  public get sum (): OrderSum {
    const cost = this.items!.map(c => c.sum).reduce((curr, next) => curr + next)
    const delivery = this.deliveryPrice ?? 0
    const vat = (cost + delivery) * 0.21
    const total = cost + delivery + vat

    return {
      cost,
      delivery,
      vat,
      total,
    }
  }
}

export interface OrderSum {
  cost?: number
  vat?: number
  delivery?: number
  total?: number
}
