export const firebaseErrors: Record<string, { title: string, message: string }> = {
  'auth/email-already-exists': {
    title: 'Email already exists.',
    message: 'This email address is already in use.',
  },
  'auth/id-token-expired': {
    title: 'Invalid session',
    message: 'The token has expired.',
  },
  'auth/invalid-credentials': {
    title: 'Invalid credentials.',
    message: 'The email address or password you entered is incorrect.',
  },
  'auth/user-not-found': {
    title: 'User not found.',
    message: 'User with this email address does not exist.',
  },
  'auth/invalid-action-code': {
    title: 'Invalid action code.',
    message: 'The action code is invalid.',
  },
  'auth/wrong-password': {
    title: 'Wrong password.',
    message: 'The password you entered is incorrect.',
  },
  'auth/weak-password': {
    title: 'Weak password.',
    message: 'The password you entered is too weak.',
  },
}
