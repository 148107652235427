import type { CaseType } from './CaseType'

export class SidePosition {
  constructor (
    public caseType: CaseType,
    public depth: number,
    public sizes: number[],
  ) {}

  static fromJson (json: Record<string, any>): SidePosition {
    return new SidePosition(
      json.case_type,
      json.depth,
      json.sizes,
    )
  }

  toJson (): Record<string, any> {
    return {
      case_type: this.caseType,
      depth: this.depth,
      sizes: this.sizes,
    }
  }
}
