export enum CaseType {
  sr = 1,
  sp = 2,
  sz = 3,
  szl = 4,
}

export const caseTypes = [
  {
    src: '/cases/sr.webp',
    name: 'Volně stojící skřín SR',
    type: CaseType.sr,
  },
  {
    src: '/cases/sp.webp',
    name: 'Rozvaděč na omítku SP',
    type: CaseType.sp,
  },
  {
    src: '/cases/sz.webp',
    name: 'Rozvaděč pod omítku SZ',
    type: CaseType.sz,
  },
  {
    src: '/cases/sz-l.webp',
    name: 'Rozváděč pod omítku s vlastní vanou SZ-L',
    type: CaseType.szl,
  },
]
