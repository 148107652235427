<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'
import { cva } from 'class-variance-authority'

const props = defineProps<{
  alert: AlertType
}>()

const { dismissAlert } = useAlert()

const containerClass = computed(() => {
  return cva('flex p-16px rounded-md space-x-24px card-shadow', {
    variants: {
      intent: {
        info: 'bg-blue-100',
        success: 'bg-green-100',
        warning: 'bg-orange-100',
        danger: 'bg-red-100',
      },
    },
  })({
    intent: props.alert.intent,
  })
})

const iconClass = computed(() => {
  return cva('text-24px', {
    variants: {
      intent: {
        info: 'text-blue-700 i-heroicons-solid-information-circle',
        success: 'text-green-600 i-heroicons-solid-check-circle',
        warning: 'text-orange-400 i-heroicons-solid-exclamation-circle',
        danger: 'text-red-500 i-heroicons-solid-exclamation-circle',
      },
    },
  })({
    intent: props.alert.intent,
  })
})

const titleClass = computed(() => {
  return cva('font-medium', {
    variants: {
      intent: {
        info: 'text-blue-900',
        success: 'text-green-900',
        warning: 'text-orange-900',
        danger: 'text-red-900',
      },
    },
  })({
    intent: props.alert.intent,
  })
})

const contentClass = computed(() => {
  return cva('text-sm', {
    variants: {
      intent: {
        info: 'text-blue-800',
        success: 'text-green-800',
        warning: 'text-orange-800',
        danger: 'text-red-800',
      },
    },
  })({
    intent: props.alert.intent,
  })
})

const closeButtonClass = computed(() => {
  return cva('p-4px ml-24px rounded-md m--4px focus:outline-none', {
    variants: {
      intent: {
        info: 'text-blue-900/70 hover:text-blue-900 hover:bg-blue-200',
        success: 'text-green-900/70 hover:text-green-900 hover:bg-green-200',
        warning: 'text-orange-900/70 hover:text-orange-900 hover:bg-orange-200',
        danger: 'text-red-900/70 hover:text-red-900 hover:bg-red-200',
      },
    },
  })({
    intent: props.alert.intent,
  })
})

onMounted(async () => {
  if (!props.alert.seconds)
    return

  await promiseTimeout(props.alert.seconds * 1000)

  dismissAlert(props.alert.id!)
})
</script>

<template>
  <div :class="containerClass">
    <UnoIcon :class="iconClass" />
    <div flex-1 space-y-4px>
      <h2 :class="titleClass">
        {{ alert.title }}
      </h2>
      <div v-if="alert.content" :class="contentClass">
        {{ alert.content }}
      </div>
    </div>
    <div shrink-0>
      <button type="button" :class="closeButtonClass" @click="dismissAlert(alert.id!)">
        <UnoIcon i-heroicons-solid-x text-24px />
      </button>
    </div>
  </div>
</template>
