export class Din {
  constructor (
    public label: string,
    public widths: number[],
  ) {}

  static fromJson (json: Record<string, any>): Din {
    return new Din(json.label, json.widths)
  }

  public toJson (): Record<string, any> {
    return {
      label: this.label,
      widths: this.widths,
    }
  }
}
