import { Company } from '~/types/Company'
import type { SaleType } from '~/types/Sale'
import { SaleGroup } from '~/types/SaleGroup'

export const useSale = defineStore('sale', () => {
  const currentSaleGroup = useState<SaleGroup | undefined>(() => undefined)

  const fetchSaleGroup = async (idCompany?: string) => {
    let idSaleGroup = 'unlogged-users'
    if (idCompany) {
      const company = Company.fromJson(superjsonParse(await $fetch(`/api/user/company/${idCompany}`)))

      if (!company.idSaleGroup)
        return
      idSaleGroup = company.idSaleGroup
    }

    const data = await $fetch(`/api/company/salegroup/${idSaleGroup}`)
    currentSaleGroup.value = SaleGroup.fromJson(superjsonParse(data))
  }

  // function getSale(t: SaleType, { from, to }: { from?: Date; to?: Date } = {}): number {
  function getSale (t: SaleType): number {
    if (!currentSaleGroup.value)
      return 0
    // const now = new Date()
    // const foundSales = currentSaleGroup.value.sales.filter(e =>
    //   e.type === t
    //     && (e.from === undefined || (from ? e.from.getTime() - (from.getTime() || now.getTime()) < 0 : true))
    //     && (e.to === undefined || (to ? e.to.getTime() - (to.getTime() || now.getTime()) > 0 : true)))

    const foundSales = currentSaleGroup.value.sales.filter(e => e.type === t)

    if (foundSales.length === 0)
      return 0

    let finalSale = 0
    for (const s of foundSales)
      finalSale += s.sale

    return finalSale
  }

  return {
    getSale,
    fetchSaleGroup,
    currentSaleGroup,
  }
})
