export class FillPrice {
  constructor (
    public toolGrid: number,
    public ElectricityPrepare: number,
    public mountPanel: number,
    public masks: number,
    public cover: number,
  ) {}

  static fromJson (json: Record<string, any>): FillPrice {
    return new FillPrice(
      json.tool_grid,
      json.electricity_prepare,
      json.mount_panel,
      json.masks,
      json.cover,
    )
  }

  public toJson (): Record<string, any> {
    return {
      tool_grid: this.toolGrid,
      electricity_prepare: this.ElectricityPrepare,
      mount_panel: this.mountPanel,
      masks: this.masks,
      cover: this.cover,
    }
  }
}
