import { Sale } from './Sale'

export class Company {
  constructor (
    public id: string,
    public name: string,
    public invoiceAddress?: CompanyAddress,
    public termsDate?: Date,
    public sales?: Sale[],
    public ordersCounter?: number,
    public idSaleGroup?: string,
  ) {
    this.sales ??= []
    this.invoiceAddress ??= new CompanyAddress()
    this.ordersCounter ??= 0
    this.termsDate ??= new Date()
  }

  static fromJson (json: Record<string, any>): Company {
    return new Company(
      json.id,
      json.name,
      CompanyAddress.fromJson(json.invoice_address),
      new Date(json.terms_date),
      (json.sales ?? []).map((saleJson: Record<string, any>) => Sale.fromJson(saleJson)),
      json.orders_counter,
      json.id_sale_group,
    )
  }

  public toJson (): Record<string, any> {
    return {
      id: this.id,
      name: this.name,
      invoice_address: this.invoiceAddress!.toJson(),
      terms_date: this.termsDate!.toISOString(),
      sales: this.sales!.map(sale => sale.toJson()),
      orders_counter: this.ordersCounter,
      id_sale_group: this.idSaleGroup,
    }
  }
}

export class CompanyAddress {
  constructor (
    public name?: string,
    public ico?: string,
    public dic?: string,
    public address?: string,
    public city?: string,
    public zip?: string,
    public country?: string,
  ) {
    this.country ??= 'CZ'
  }

  public toJson (): Record<string, any> {
    return {
      ico: this.ico,
      dic: this.dic,
      address: this.address,
      city: this.city,
      zip: this.zip,
      country: this.country,
      name: this.name,
    }
  }

  static fromJson (json: Record<string, any>) {
    return new CompanyAddress(
      json.name ?? '' as string,
      json.ico ?? '' as string,
      json.dic ?? '' as string,
      json.address ?? '' as string,
      json.city ?? '' as string,
      json.zip ?? '' as string,
      json.country as string,
    )
  }
}
