<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import { Order } from '~/types/Order'
import { Company } from '~/types/Company'
import { Cart } from '~/types/Cart'
import { OrderItem } from '~/types/OrderItem'
import { CaseConfig } from '~/types/CaseConfig'

const { closeOrder, openCart, openOrderComplete } = useModals()

const { cart } = storeToRefs(useCart())

const { user } = storeToRefs(useUser())

const { calcDelivery } = useDelivery()

const { countryOptions } = storeToRefs(useRemoteConfig())

const orderTerms = ref(false)
const order = ref<Order>(new Order())

async function loadOrderData () {
  order.value.idCompany = user.value!.idCompany
  order.value.userCreated = user.value!.fullName
  order.value.fiscalYear = new Date().getFullYear()
  order.value.datePurchased = new Date()
  order.value.userPurchased = user.value!.fullName
  order.value.itemCount = cart.value.items.length
  order.value.contactEmail = user.value!.email
  order.value.items = cart.value.items.map(i => new OrderItem(i.caseConfig.caseLabel, i.quantity, CaseConfig.fromJson(i.caseConfig.toJson()), i.caseConfig.casePrice, i.caseConfig.getSummaryItems().map(si => `${si.label}${si.value ? `: ${si.value}` : ''}`).join(', ')))

  const companyData = await $fetch(`/api/user/company/${user.value!.idCompany}`)
  order.value.company = Company.fromJson(superjsonParse(companyData))
}

await loadOrderData()

function back () {
  closeOrder()
  openCart()
}

async function completeOrder () {
  order.value.dateCreated = new Date()

  await $fetch('/api/order', {
    method: 'POST',
    body: order.value.toJson(),
  })
  cart.value = new Cart([], new Date())
  closeOrder()
  openOrderComplete()
}

const isValid = computed(() => {
  if (!orderTerms.value || !order.value.contactName || !order.value.contactPhone || !order.value.deliveryAddress?.name || !order.value.deliveryAddress?.address || !order.value.deliveryAddress?.city || !order.value.deliveryAddress?.zip || !order.value.deliveryAddress?.country)
    return false
  return true
})

function zipChanged (value: string) {
  if (value.length === 5 && !value.includes(' ')) {
    order.value.deliveryAddress!.zip = `${value.slice(0, 3)} ${value.slice(3)}`
    console.log(order.value.deliveryAddress!.zip)
  }
  else
    order.value.deliveryAddress!.zip = value
}

const city = computed(() => order.value.deliveryAddress!.city)
const zip = computed(() => order.value.deliveryAddress!.zip)
const country = computed(() => order.value.deliveryAddress!.country)
const withDelivery = computed(() => order.value.withDelivery)

const throttledCalcDelivery = useThrottleFn(async () => {
  const deliveryData = await calcDelivery(order.value)
  order.value.deliveryKm = deliveryData?.deliveryKm
  order.value.deliveryPrice = deliveryData?.deliveryPrice
}, 100)

watch([zip, country, withDelivery], () => {
  throttledCalcDelivery()
})

watch(city, () => {
  order.value.deliveryAddress!.zip = undefined
})
</script>

<template>
  <VueFinalModal
    class="flex flex-col justify-center items-center"
    content-class="grid grid-cols-1 md:grid-cols-2 gap-x-32px bg-white p-32px rounded-12px w-1440px overflow-y-scroll max-h-1200px"
  >
    <div>
      <h1 font-600 text-24px leading-24px>
        Kontaktní osoba při dodání
      </h1>
      <div bg-app-bg-card rounded-xl p-16px mt-16px>
        <KInput
          :model-value="order.contactName ?? ''" type="text" label="Jméno a přijmení" placeholder="Zadejte jméno"
          name="contactName" required @update:model-value="(value) => order.contactName = value"
        />
        <KInput
          :model-value="order.contactPhone ?? ''" type="text" label="Telefon" placeholder="Zadejte telefon"
          name="contactPhone" required mt-8px @update:model-value="(value) => order.contactPhone = value"
        />
      </div>
      <h1 font-600 text-24px leading-24px mt-16px>
        Dodací údaje
      </h1>
      <div bg-app-bg-card rounded-xl p-16px mt-24px>
        <KInput
          :model-value="order.deliveryAddress!.name ?? ''" type="text" label="Název společnosti"
          placeholder="Zadejte název společnosti" name="companyName" required
          @update:model-value="(value) => order.deliveryAddress!.name = value"
        />
        <KInput
          :model-value="order.deliveryAddress!.ico ?? ''" type="number" label="Zadejte IČO společnosti"
          placeholder="Zadejte IČO společnosti" name="companyIco" mt-8px
          @update:model-value="(value) => order.deliveryAddress!.ico = value"
        />
        <KInput
          :model-value="order.deliveryAddress!.dic ?? ''" type="number" label="DIČ"
          placeholder="Zadejte pokud máte DIČ" name="companyVat" mt-8px
          @update:model-value="(value) => order.deliveryAddress!.dic = value"
        />
        <div grid grid-cols-2 gap-x-8px>
          <KInput
            :model-value="order.deliveryAddress!.address ?? ''" type="text" label="Adresa"
            placeholder="Zadejte adresu" name="companyAddress" required mt-8px
            @update:model-value="(value) => order.deliveryAddress!.address = value"
          />
          <KInput
            :model-value="order.deliveryAddress!.city ?? ''" type="text" label="Město"
            placeholder="Zadejte město" name="companyAddressCity" required mt-8px
            @update:model-value="(value) => order.deliveryAddress!.city = value"
          />
          <KInput
            :model-value="order.deliveryAddress!.zip ?? ''" type="text" label="PSČ"
            placeholder="Zadejte PSČ" name="companyAddressZip" required mt-8px
            @update:model-value="(value) => zipChanged(value)"
          />
          <div flex flex-col gap-8px pt-8px>
            <p text-14px leading-16px font-400>
              Země
            </p>
            <KLabelSelect :model-value="countryOptions.find((o) => o.value === order.deliveryAddress!.country)" :options="countryOptions" full-width do-not-lock @update:model-value="(val) => order.deliveryAddress!.country = val.value" />
          </div>
        </div>
      </div>
      <h1 font-600 text-24px leading-24px mt-16px>
        Fakturační údaje
      </h1>
      <div bg-app-bg-card rounded-xl p-16px mt-24px>
        <KInput
          :model-value="order.company!.name ?? ''" type="text" label="Název společnosti"
          placeholder="Zadejte název společnosti" name="companyName" readonly
        />
        <KInput
          :model-value="order.company!.invoiceAddress!.ico ?? ''" type="number" label="Zadejte IČO společnosti"
          placeholder="Zadejte IČO společnosti" name="companyIco" mt-8px readonly
        />
        <KInput
          :model-value="order.company!.invoiceAddress!.dic ?? ''" type="number" label="DIČ"
          placeholder="Zadejte pokud máte DIČ" name="companyVat" mt-8px readonly
        />
        <div grid grid-cols-2 gap-x-8px>
          <KInput
            :model-value="order.company!.invoiceAddress!.address ?? ''" type="text" label="Adresa"
            placeholder="Zadejte adresu" name="companyAddress" mt-8px readonly
          />
          <KInput
            :model-value="order.company!.invoiceAddress!.city ?? ''" type="text" label="Město"
            placeholder="Zadejte město" name="companyAddressCity" mt-8px readonly
          />
          <KInput
            :model-value="order.company!.invoiceAddress!.zip ?? ''" type="number" label="PSČ"
            placeholder="Zadejte PSČ" name="companyAddressZip" mt-8px readonly
          />
          <div flex flex-col gap-8px pt-8px>
            <p text-14px leading-16px font-400>
              Země
            </p>
            <KLabelSelect :model-value="countryOptions.find((o) => o.value === order.company!.invoiceAddress!.country) ?? { label: 'Nezadáno', value: 'Nezadáno' }" :options="countryOptions" full-width readonly />
          </div>
        </div>
      </div>
      <h1 font-600 text-24px leading-24px mt-16px>
        Poznámka
      </h1>
      <textarea id="comment" v-model="order.note" mt-12px rows="4" name="comment" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-app-blue sm:text-sm sm:leading-6" />
    </div>
    <div>
      <h1 font-600 text-24px leading-24px mb-16px>
        Souhrn položek
      </h1>
      <CaseConfigCard
        v-for="item of cart.items" :key="item.caseConfig.id" :case-config="item.caseConfig"
        :quantity="item.quantity || 0" :class="{ 'mb-16px': cart.isNotEmpty }" readonly
      />
      <div v-if="cart.isNotEmpty" flex flex-col gap-8px w-full bg-app-bg-card p-16px rounded-lg>
        <div flex items-center justify-between>
          <h2>Cena Celkem:</h2>
          <h2>{{ formatPrice(cart.sum.cost) }} Kč bez DPH</h2>
        </div>
        <div flex items-center justify-between>
          <h2>Doprava:</h2>
          <h2>{{ order.deliveryPrice ? formatPrice(order.deliveryPrice) : '0,00' }} Kč</h2>
        </div>
        <div flex items-center gap-8px>
          <input
            :checked="order.withDelivery" type="checkbox" w-18px h-18px
            border="2px app-stroke" rounded-sm bg-white text-app-blue focus:ring-none cursor-pointer @click="order.withDelivery = !order.withDelivery"
          >
          <span text-14px text-black>Cena za dopravu Vám bude vyčíslena individuálně po domluvě.</span>
        </div>
        <div flex items-center gap-8px>
          <input
            :checked="!order.withDelivery" type="checkbox" w-18px h-18px
            border="2px app-stroke" rounded-sm bg-white text-app-blue focus:ring-none cursor-pointer @click="order.withDelivery = !order.withDelivery"
          >
          <span text-14px text-black>Vlastní doprava</span>
        </div>
        <div flex items-center justify-between>
          <h2>DPH:</h2>
          <h2>{{ formatPrice(cart.sum.vat!) }} Kč</h2>
        </div>
        <div flex items-center justify-between border-t="app-stroke 1px" pt-8px>
          <h1 font-semibold>
            Součet:
          </h1>
          <h1 font-semibold>
            {{ formatPrice(cart.sum.total!) }} Kč včetně DPH
          </h1>
        </div>
      </div>
      <div flex items-start gap-8px mt-16px ml-4px>
        <input
          v-model="orderTerms" type="checkbox" w-18px h-18px border="2px app-stroke" rounded-sm bg-white
          text-app-blue focus:ring-none cursor-pointer
        >
        <div>
          <div text-12px text-black>
            * Souhlasím s obchodními podmínkami a beru na vědomí zpracování osobních údajů pro účely vyřízení
            objednávky.
          </div>
          <NuxtLink to="/docs/Obchodní-podmínky-pro-e-shop.pdf" target="blank" focus:outline-none>
            [<span text-app-blue text-14px>ZOBRAZIT</span>]
          </NuxtLink>
        </div>
      </div>
      <div flex justify-end gap-16px mt-32px>
        <button type="button" text-btn text-app-text-black underline text-16px @click="back()">
          Zpět
        </button>
        <button :disabled="!isValid" :class="{ 'opacity-50': !isValid }" type="button" text-btn underline text-16px @click="completeOrder()">
          Dokončit objednávku
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>
