import { CableHole, CableHolePosition, CableHoleType, SealHoleType } from './CableHole'
import { Case } from './Case'
import { CaseType } from './CaseType'
import { CaseVentilation, CaseVentilationPosition, skm30Price, skm45Price, skm90Price } from './CaseVentilation'
import { Fill, None, ToolGridContent } from './Fill'
import type { PdfData } from './PdfData'
import { SaleType } from './Sale'
import { CustomSizeType, SizeType } from './SizeType'
import { SummaryItem } from './SummaryItem'
import { skmVentilation, Ventilation, VentilationType } from './Ventilation'

export const maxVentilations = 4
export const simpleVentilationsHeight = 1000
export const minimalVentilationHeight = 700

export const electricityRequirementInsideHeights = {
  sr: [1900, 1600, 1400, 1100],
  other: [1950, 1650, 1450, 1150],
}

export class CaseConfig {
  constructor (
    public id?: string,
    public label?: string,
    public tabLabel?: string,
    public caseInfo?: Case,
    public pickedWidth?: number,
    public pickedHeight?: number,
    public pickedDepth?: number,
    public pickedLabel?: string,
    public customHeight?: number,
    public customWidth?: number,
    public customDepth?: number,
    public notesCompany?: string,
    public notesLabel?: string,
    public notesNotes?: string,
    public caseType?: CaseType,
    public scale?: number,
    public fill?: Fill,
    public ventilations?: CaseVentilation[],
    public cableHoles?: CableHole[],
    public cableHoleType?: CableHoleType,
    public color?: string,
    public cableHoleAutoAdded?: boolean,
    public step?: number,
    public sizeType?: SizeType,
    public customSizeType?: CustomSizeType,
    public pickedEi?: number,
    public pdfData?: PdfData,
  ) {
    this.id ??= Date.now().toString()
    this.scale ??= 2.5
    // ! override by inherited class dependent on fillValue which cant be passed-
    // ! from JsonSerializable by default, we need to explicitly call this method
    // ! and use the stored json in Fill class
    this.fill ??= new Fill()
    this.ventilations ??= [] as CaseVentilation[]
    this.cableHoles ??= [] as CableHole[]
    this.color ??= '7035'
    this.cableHoleAutoAdded ??= false
    this.step ??= 1

    this.sizeType ??= SizeType.catalog
    this.customSizeType ??= CustomSizeType.outerDimensions
  }

  static fromJson (json: Record<string, any>): CaseConfig {
    return new CaseConfig(
      json.id,
      json.label,
      json.tab_label,
      json.case_info ? Case.fromJson(json.case_info) : undefined,
      json.picked_width,
      json.picked_height,
      json.picked_depth,
      json.picked_label,
      json.custom_height,
      json.custom_width,
      json.custom_depth,
      json.notes_company,
      json.notes_label,
      json.notes_notes,
      json.case_type,
      json.scale,
      json.fill ? Fill.fromJson(json.fill) : undefined,
      (json.ventilations ?? []).map((ventilationJson: Record<string, any>) => CaseVentilation.fromJson(ventilationJson)),
      (json.cable_holes ?? []).map((cableHoleJson: Record<string, any>) => CableHole.fromJson(cableHoleJson)),
      json.cable_hole_type === 1 ? CableHoleType.cutout : json.cable_hole_type === 2 ? CableHoleType.ukm : json.cable_hole_type === 3 ? CableHoleType.tkm : undefined,
      json.color,
      json.cable_hole_auto_added,
      json.step,
      json.size_type,
      json.custom_size_type,
      json.picked_ei,
      json.pdfData,
    )
  }

  public toJson (): Record<string, any> {
    return {
      label: this.label,
      tab_label: this.tabLabel,
      case_info: this.caseInfo?.toJson(),
      picked_width: this.pickedWidth,
      picked_height: this.pickedHeight,
      picked_depth: this.pickedDepth,
      picked_label: this.pickedLabel,
      custom_height: this.customHeight,
      custom_width: this.customWidth,
      custom_depth: this.customDepth,
      notes_company: this.notesCompany,
      notes_label: this.notesLabel,
      notes_notes: this.notesNotes,
      case_type: this.caseType,
      scale: this.scale,
      id: this.id,
      fill: this.fill?.toJson(),
      ventilations: this.ventilations?.map(ventilation => ventilation.toJson()),
      cable_holes: this.cableHoles?.map(cableHole => cableHole.toJson()),
      cable_hole_type: this.cableHoleType === CableHoleType.cutout ? 1 : this.cableHoleType === CableHoleType.ukm ? 2 : this.cableHoleType === CableHoleType.tkm ? 3 : undefined,
      color: this.color,
      cable_hole_auto_added: this.cableHoleAutoAdded,
      step: this.step,
      size_type: this.sizeType,
      custom_size_type: this.customSizeType,
      picked_ei: this.pickedEi,
      pdfData: this.pdfData,
    }
  }

  public reset (): void {
    this.tabLabel = ''
    this.caseType = undefined
    this.caseInfo = undefined
    this.fill = new None()
    this.ventilations = []
    this.cableHoles = []
    this.cableHoleAutoAdded = false
    this.step = 1

    this.sizeType = SizeType.catalog
    this.customSizeType = CustomSizeType.outerDimensions
    this.pickedWidth = undefined
    this.pickedHeight = undefined
    this.pickedDepth = undefined
    this.pickedLabel = undefined
    this.customHeight = undefined
    this.customWidth = undefined
    this.customDepth = undefined
    this.pickedEi = 30
  }

  public get pdfEi (): 'EI30' | 'EI45' | 'EI60' | 'EI90' {
    return `EI${this.pickedEi}` as 'EI30' | 'EI45' | 'EI60' | 'EI90'
  }

  public get caseLabel (): string {
    const label = this.caseInfo?.label ?? ''
    if (this.ventilations?.length ?? -1 > 0)
      return label.split('-S')[0]
    return label
  }

  public get currentTabLabel (): string {
    if (this.tabLabel)
      return this.tabLabel
    if (this.caseInfo)
      return this.caseLabel
    return 't(newTab)'
  }

  public setCase (c: Case): void {
    this.caseInfo = c

    this.optimizeScale()
  }

  public get isSr (): boolean {
    return this.caseType === CaseType.sr
  }

  public get isSp (): boolean {
    return this.caseType === CaseType.sp
  }

  public get isSz (): boolean {
    return this.caseType === CaseType.sz || this.caseType === CaseType.szl
  }

  public get isPureSz (): boolean {
    return this.caseType === CaseType.sz
  }

  public get isSzl (): boolean {
    return this.caseType === CaseType.szl
  }

  public get hasOnlyDoors (): boolean {
    return !!this.fill?.noneOnlyDoors && this.isPureSz
  }

  public get isSealHole (): boolean {
    return this.cableHoleType === CableHoleType.ukm || this.cableHoleType === CableHoleType.tkm
  }

  public get casePrice (): number {
    if (!this.caseInfo?.fillPrice || !this.caseInfo?.casePrice)
      return 0

    const { getSale } = useSale()

    let price = this.caseInfo.casePrice
    let caseSale = 0

    if (this.isSr && this.caseInfo.ei === 30)
      caseSale = getSale(SaleType.sr30)
    if (this.isSr && this.caseInfo.ei === 45)
      caseSale = getSale(SaleType.sr45)
    if (this.isSr && this.caseInfo.ei === 60)
      caseSale = getSale(SaleType.sr60)
    if (this.isSr && this.caseInfo.ei === 90)
      caseSale = getSale(SaleType.sr90)

    if (this.isSp && this.caseInfo.ei === 30)
      caseSale = getSale(SaleType.sp30)
    if (this.isSp && this.caseInfo.ei === 45)
      caseSale = getSale(SaleType.sp45)
    if (this.isSp && this.caseInfo.ei === 60)
      caseSale = getSale(SaleType.sp60)

    if (this.isPureSz && this.caseInfo.ei === 30)
      caseSale = getSale(SaleType.sz30)
    if (this.isPureSz && this.caseInfo.ei === 45)
      caseSale = getSale(SaleType.sz45)
    if (this.isPureSz && this.caseInfo.ei === 60)
      caseSale = getSale(SaleType.sz60)

    if (this.isSzl && this.caseInfo.ei === 30)
      caseSale = getSale(SaleType.sz30)
    if (this.isSzl && this.caseInfo.ei === 45)
      caseSale = getSale(SaleType.sz45)
    if (this.isSzl && this.caseInfo.ei === 60)
      caseSale = getSale(SaleType.sz60)

    price = price - (price / 100 * caseSale)

    // fill

    price += this.toolgridPrice()

    price += this.electricityPreparePrice()

    price += this.mountPanelPrice()

    if (this.isPureSz && !this.fill?.noneOnlyDoors)
      price += this.caseInfo.fillPrice.cover

    // vents

    const ventSale = getSale(SaleType.ventilations)

    console.log('vents:', this.ventilations?.length)
    for (const vent of this.ventilations ?? []) {
      if (vent.ventilator)
        price += vent.ventilator.price - (vent.ventilator.price / 100 * ventSale)

      price += this.caseInfo!.ei === 30 ? skm30Price : this.caseInfo!.ei === 90 ? skm90Price : skm45Price
    }

    // cable holes

    const cableHoleSale = getSale(SaleType.cableHoles)
    for (const cb of this.cableHoles ?? []) {
      const cbPrice = cb.price || 0
      price += cbPrice - (cbPrice / 100 * cableHoleSale)
    }

    return price
  }

  public toolgridPrice (): number {
    if (!this.caseInfo?.fillPrice)
      return 0
    const { getSale } = useSale()
    const gridElectroMasksSale = getSale(SaleType.grid)

    let price = 0
    if (!this.fill?.toolGrid)
      return price
    const toolgrid = this.fill.asToolGrid

    if (toolgrid.content === ToolGridContent.full) {
      price += this.caseInfo!.fillPrice!.toolGrid - (this.caseInfo!.fillPrice!.toolGrid / 100 * gridElectroMasksSale)
      if (toolgrid.masks!.length > 0 && this.caseInfo!.fillPrice!.masks)
        price += this.caseInfo!.fillPrice!.masks - (this.caseInfo!.fillPrice!.masks / 100 * gridElectroMasksSale)
    }
    else if (toolgrid.fillValue === ToolGridContent.onlySides) {
      price += (this.caseInfo!.fillPrice!.toolGrid - (this.caseInfo!.fillPrice!.toolGrid / 100 * gridElectroMasksSale)) / 2
    }
    return price
  }

  public electricityPreparePrice (): number {
    const { getSale } = useSale()
    const gridElectroMasksSale = getSale(SaleType.grid)

    return this.fill?.electricityPrepare && this.caseInfo!.fillPrice ? this.caseInfo!.fillPrice!.ElectricityPrepare - (this.caseInfo!.fillPrice!.ElectricityPrepare / 100 * gridElectroMasksSale) : 0
  }

  public mountPanelPrice (): number {
    const { getSale } = useSale()
    const panelSale = getSale(SaleType.panel)
    return this.fill?.mountPanel && this.caseInfo!.fillPrice ? this.caseInfo!.fillPrice!.mountPanel - (this.caseInfo!.fillPrice!.mountPanel / 100 * panelSale) : 0
  }

  public optimizeScale (): void {
    if (!this.caseInfo)
      return

    const diagonal = Math.sqrt((this.caseInfo.outDimensions.h * this.caseInfo.outDimensions.h) + (this.caseInfo.outDimensions.w * this.caseInfo.outDimensions.w))

    let optimalScale = diagonal / 1205

    optimalScale += 0.4

    this.scale = optimalScale
  }

  public get isCustomDimensions (): boolean {
    if (this.caseInfo && this.caseInfo.orderNumber)
      return false
    return true
  }

  public get coverLabel (): string | undefined {
    if (!this.isSz || this.fill?.noneOnlyDoors)
      return undefined

    const { covers } = useRemoteConfig()

    const width = this.caseInfo?.outDimensions.w
    const height = this.caseInfo?.outDimensions.h
    const cover = covers.find(c => c.height === height && c.width === width)

    if (!cover)
      return 'Nenalezeno'
    return cover.label
  }

  public get sideLabel (): string | undefined {
    if ((!this.fill?.toolGrid || !this.fill?.asToolGrid.hasSides) && !this.fill?.electricityPrepare)
      return undefined

    const { sides } = useRemoteConfig()

    const height = this.caseInfo?.outDimensions.h
    const side = sides.find(s => s.heights.includes(height ?? 0))

    if (!side)
      return 'Nenalezeno'
    return side.label
  }

  public get dinLabel (): string | undefined {
    if ((!this.fill?.toolGrid || this.fill?.asToolGrid.content !== ToolGridContent.full) && !this.fill?.electricityPrepare)
      return undefined

    const { dins } = useRemoteConfig()

    const width = this.caseInfo?.outDimensions.w
    const din = dins.find(d => d.widths.includes(width ?? 0))
    if (!din)
      return 'Nenalezeno'
    return din.label
  }

  public get dinCount (): number {
    // ? din count pouze pro kosik
    if (!this.fill?.toolGrid)
      return 0

    if (this.hasMasks)
      return this.fill!.asToolGrid.masks!.filter(m => m.height > 50).length

    const dinCount = Math.floor(this.caseInfo!.inDimensions.h / 150)
    let count = 0
    for (let i = 1; i <= dinCount; i++) {
      if (this.isDinPositionAvailable((i === 1 ? 0 : 150 * (i - 1)) + 55,
        (i === 1 ? 0 : 150 * (i - 1)) + 55 + 40))
        count++
    }
    return count
  }

  public get standardDinCount (): number[] {
    return Array.from({ length: Math.floor(this.caseInfo!.inDimensions.h / 150) }, (_, index) => index)
  }

  public get belCount (): number | undefined {
    if (this.fill?.toolGrid && this.fill.asToolGrid.content === ToolGridContent.full) {
      return Math.floor(Math.floor(this.dinCount) / 2)
    }
    else if (this.fill?.electricityPrepare) {
      let dinCount = 0
      for (const maskTop of this.fill.asElectricity.masksTop!) {
        if (maskTop.height === 300)
          dinCount += 2
        else
          dinCount += 1
      }

      dinCount += this.fill.asElectricity.masksBottom!.length

      return Math.floor(Math.floor(dinCount) / 2)
    }

    return undefined
  }

  public get fillValueByType (): number | undefined {
    let value: number | undefined

    if (this.fill?.toolGrid)
      value = 1
    if (this.fill?.mountPanel)
      value = 2
    if (this.fill?.emptyCase)
      value = 3

    return value
  }

  public get hasMasks (): boolean {
    if ((this.fill?.toolGrid && this.fill.asToolGrid.masks!.length > 0) || this.fill?.electricityPrepare)
      return true

    return false
  }

  public get groupedMasks (): GroupedItemQuantity[] {
    const groupedMasks: Map<string, GroupedItemQuantity> = new Map()
    if (!this.hasMasks)
      return Array.from(groupedMasks.values())

    if (this.fill?.toolGrid) {
      for (const m of this.fill.asToolGrid.masks!) {
        const key = m.label
        if (!groupedMasks.has(key))
          groupedMasks.set(key, { label: m.label, quantity: 0 })
        groupedMasks.get(key)!.quantity++
      }
    }

    if (this.fill?.electricityPrepare) {
      for (const m of this.fill.asElectricity.masksTop!) {
        const key = m.label
        if (!groupedMasks.has(key))
          groupedMasks.set(key, { label: m.label, quantity: 0 })
        groupedMasks.get(key)!.quantity++
      }

      for (const m of this.fill.asElectricity.masksBottom!) {
        const key = m.label
        if (!groupedMasks.has(key))
          groupedMasks.set(key, { label: m.label, quantity: 0 })
        groupedMasks.get(key)!.quantity++
      }
    }

    return Array.from(groupedMasks.values())
  }

  public getCableHoleByPosition (position: CableHolePosition): CableHole[] {
    return this.cableHoles?.filter(ch => ch.position === position) ?? []
  }

  public addCableHole (ch: CableHole): void {
    this.cableHoles?.push(ch)
  }

  public removeCableHole (ch: CableHole): void {
    this.cableHoles?.splice(this.cableHoles?.indexOf(ch), 1)
  }

  public getCableHoleTop (): CableHole[] {
    return this.getCableHoleByPosition(CableHolePosition.top)
  }

  public getCableHoleTopLeft (): CableHole[] {
    return this.getCableHoleByPosition(CableHolePosition.topLeft)
  }

  public getCableHoleTopRight (): CableHole[] {
    return this.getCableHoleByPosition(CableHolePosition.topRight)
  }

  public getCableHoleBottom (): CableHole[] {
    return this.getCableHoleByPosition(CableHolePosition.bottom)
  }

  public getCableHoleBack (): CableHole[] {
    return this.getCableHoleByPosition(CableHolePosition.back)
  }

  public get freeSpaceInToolGrid (): number | undefined {
    if (!this.fill?.toolGrid || !this.caseInfo)
      return undefined
    return this.caseInfo.inDimensions.h - this.fill.asToolGrid.height
  }

  public getVentilationByPosition (position: CaseVentilationPosition): CaseVentilation | undefined {
    return this.ventilations!.find(cv => cv.position === position)
  }

  public addVentilation (cv: CaseVentilation): void {
    const ventToRemove = this.getVentilationByPosition(cv.position!)
    if (ventToRemove)
      this.removeVentilation(ventToRemove)

    this.ventilations?.push(cv)
  }

  public removeVentilation (cv: CaseVentilation): void {
    this.ventilations?.splice(this.ventilations?.indexOf(cv), 1)
  }

  public get groupedVentilations (): GroupedItemQuantity[] {
    const caseVentilations = this.ventilations!.map(v => v)
    const groupedVentilations = new Map<string, GroupedItemQuantity>()

    // go through vents and to ventilators add grids

    const ventilatorVentilations = this.ventilations!.filter(v => v.ventilator)

    for (const caseVent of ventilatorVentilations) {
      const grid = skmVentilation
      const caseVentGrid = new CaseVentilation(grid)
      caseVentGrid.size = grid.size
    }

    for (const cv of caseVentilations) {
      let vent = cv.ventilator
      if (!vent)
        vent = skmVentilation
      let label = vent?.label
      if (cv.ventilator)
        label = vent.label
      else
        label = cv.label
      const key = label
      if (!groupedVentilations.has(key))
        groupedVentilations.set(key, { label: key, quantity: 0 })
      groupedVentilations.get(key)!.quantity++
    }

    return Array.from(groupedVentilations.values())
  }

  public get maxVentilators (): number {
    return (this.caseInfo?.outDimensions?.w || 0) > 1000 ? 2 : 1
  }

  public get ventilatorsCount (): number {
    return (this.ventilations?.filter(v => v.ventilator) || []).length
  }

  public get ventilatorAllowed (): boolean {
    if (this.ventilatorsCount > this.maxVentilators)
      return false
    return true
  }

  public get ventilationAllowed (): boolean {
    return this.ventilations?.length !== maxVentilations
  }

  public get hasSimpleVentilation (): boolean {
    return this.caseInfo!.outDimensions!.h < simpleVentilationsHeight
  }

  public get ventilationForCaseAllowed (): boolean {
    return this.caseInfo!.outDimensions.h > minimalVentilationHeight
  }

  public isDinPositionAvailable (from: number, to: number) {
    const caseVents = this.ventilations?.filter(v => (v.position === CaseVentilationPosition.frontTop || v.position === CaseVentilationPosition.frontBottom || v.position === CaseVentilationPosition.frontLeftTop || v.position === CaseVentilationPosition.frontRightTop || v.position === CaseVentilationPosition.frontLeftBottom || v.position === CaseVentilationPosition.frontRightBottom) && v.ventilator)

    if (!caseVents || caseVents?.length === 0)
      return true

    for (const caseVent of caseVents) {
      const ventilatorHeight = caseVent.size! + 60

      let ventilatorFrom = (this.caseInfo!.outDimensions.h - this.caseInfo!.inDimensions.h) / 2
      let ventilatorTo = ventilatorFrom + ventilatorHeight

      if (caseVent.position === CaseVentilationPosition.frontBottom) {
        ventilatorFrom = this.caseInfo!.inDimensions.h - ventilatorTo
        ventilatorTo = ventilatorFrom + ventilatorHeight
      }

      if ((from > ventilatorFrom && from < ventilatorTo) || (to > ventilatorFrom && to < ventilatorTo)) {
        if (this.getVentEnd(caseVent) >= this.marginSidewallFromSide)
          return false
      }
    }
    return true
  }

  public get marginPanelFromSide (): number {
    return this.isSr ? 50 : 30
  }

  public get marginSidewallFromSide (): number {
    if (this.isSp)
      return 30
    if (this.isSz)
      return 55

    const { sidePositions } = useRemoteConfig()

    const sidesPosition = sidePositions.find(p => p.caseType === this.caseType && p.depth === this.caseInfo!.outDimensions.d)

    if (!sidesPosition)
      return 80

    let margin = sidesPosition.sizes[0]

    const ventilation1 = this.getVentilationByPosition(CaseVentilationPosition.frontTop) || this.getVentilationByPosition(CaseVentilationPosition.frontLeftTop) || this.getVentilationByPosition(CaseVentilationPosition.frontRightTop)
    const ventilation2 = this.getVentilationByPosition(CaseVentilationPosition.frontBottom) || this.getVentilationByPosition(CaseVentilationPosition.frontLeftBottom) || this.getVentilationByPosition(CaseVentilationPosition.frontRightBottom)

    if (ventilation1 || ventilation2) {
      let ventilation1End = 0
      const ventilation2End = 0

      if (ventilation1)
        ventilation1End = this.getVentEnd(ventilation1)
      if (ventilation2)
        ventilation1End = this.getVentEnd(ventilation2)

      const higherEnd = ventilation1End > ventilation2End ? ventilation1End : ventilation2End

      let foundMargin = sidesPosition.sizes.find(s => s > higherEnd)
      if (!foundMargin)
        foundMargin = sidesPosition.sizes.find(s => s === higherEnd)
      if (foundMargin)
        margin = foundMargin
    }

    return margin
  }

  public get marginDinFromSide (): number {
    return this.marginSidewallFromSide + 45
  }

  public get marginMaskFromSide (): number {
    return this.marginDinFromSide - 15
  }

  public get widthSidewallFromSide (): number {
    return 110
  }

  public get caseSideWidth (): number {
    return this.isSr ? 75 : 30
  }

  public getVentMargin (gridDepth: number): number {
    let diff = gridDepth - this.caseSideWidth
    if (diff < 0)
      diff += 2
    return diff
  }

  public getVentMarginSide (gridDepth: number): number {
    let depthWidth = Math.floor((this.caseInfo!.outDimensions.d - this.caseInfo!.inDimensions.d) / 2)
    if (this.isSz)
      depthWidth = this.caseInfo!.outDimensions.d
    let diff = gridDepth - depthWidth
    if (diff < 0)
      diff += 2
    return diff
  }

  public getVentEnd (cv: CaseVentilation): number {
    return cv.ventilatorDepth + this.getVentMarginSide(cv.gridDepth)
  }

  public get frontCaseStyle () {
    return {
      width: `${this.caseInfo!.outDimensions.w / this.scale!}px`,
      height: `${this.caseInfo!.outDimensions.h / this.scale!}px`,
    }
  }

  public get sideCaseStyle () {
    return {
      width: `${this.caseInfo!.outDimensions.d / this.scale!}px`,
      height: `${this.caseInfo!.outDimensions.h / this.scale!}px`,
    }
  }

  public get topCaseStyle () {
    return {
      width: `${this.caseInfo!.outDimensions.w / this.scale!}px`,
      height: `${this.caseInfo!.outDimensions.d / this.scale!}px`,
    }
  }

  public get standardCapacity (): number | undefined {
    // const { caseCapacity } = useRemoteConfig()

    // if (!caseCapacity)
    //   return undefined
    // const key = Object.keys(caseCapacity!).find(el => this.caseLabel.startsWith(el))
    // if (!key)
    //   return undefined
    // return caseCapacity[key]
    return this.caseInfo?.modules
  }

  public get isElectricityAvailable (): boolean {
    if (this.caseInfo!.orderNumber === undefined)
      return false
    if (this.isSr)
      return electricityRequirementInsideHeights.sr.includes(this.caseInfo!.inDimensions.h)
    return electricityRequirementInsideHeights.other.includes(this.caseInfo!.inDimensions.h)
  }

  public get colorName (): string {
    if (this.color === '7035')
      return 'RAL 7035 - světlě šedá'

    return 'RAL 9016 - bílá'
  }

  public getSummaryItems (): SummaryItem[] {
    const items: SummaryItem[] = []

    if (this.fill?.emptyCase)
      items.push(new SummaryItem('Prázdná skříň bez úchytů'))

    if (this.fill?.toolGrid && this.fill?.asToolGrid.content === ToolGridContent.onlyPrepare)
      items.push(new SummaryItem('Skříň s úchyty pro vlastní přístrojový rošt'))

    if (this.coverLabel !== 'Nenalezeno' && this.coverLabel && this.isPureSz)
      items.push(new SummaryItem('Ochranný kryt', this.coverLabel, 1))

    if (this.fill?.mountPanel)
      items.push(new SummaryItem('Montážní panel', undefined, 1))

    if (this.sideLabel !== 'Nenalezeno' && this.sideLabel)
      items.push(new SummaryItem('Bočnice', this.sideLabel, 1))

    if (this.dinLabel !== 'Nenalezeno' && this.dinLabel && !this.fill?.electricityPrepare)
      items.push(new SummaryItem('DIN lišty', this.dinLabel, this.dinCount))

    if (this.belCount)
      items.push(new SummaryItem('Úchyty', 'BEL-1', this.belCount))

    if (this.belCount)
      items.push(new SummaryItem('Úchyty', 'BEL-12', this.belCount))

    for (const groupedItem of this.groupedMasks)
      items.push(new SummaryItem('Krycí desky', groupedItem.label, groupedItem.quantity))

    for (const groupedItem of this.groupedVentilations) {
      items.push(new SummaryItem('Ventilace', groupedItem.label, groupedItem.quantity))
    }

    if (this.fill?.electricityPrepare && this.isElectricityAvailable) {
      items.push(new SummaryItem('Elektroměr. vana', `BPZ-MT-${this.caseInfo!.caseWidthCeil}/400-${this.fill.asElectricity.covers![0].moduleCount}`, this.fill.asElectricity.covers!.length))
      items.push(new SummaryItem('Elektroměr. deska', 'ZBR', this.fill.asElectricity.desksCount))
      const masksTop = this.fill!.asElectricity!.masksTop
      const numberOfTopDins = !masksTop?.length ? 0 : masksTop?.length === 1 && masksTop[0].height === 300 ? 2 : 1
      items.push(new SummaryItem('DIN lišty', this.dinLabel, numberOfTopDins + (this.fill?.asElectricity?.masksBottom?.length ?? 0)))
    }

    if (this.isSealHole) {
      const ukm1Holes = this.cableHoles?.filter(cb => cb.sealType === SealHoleType.circles)
      const ukm2Holes = this.cableHoles?.filter(cb => cb.sealType === SealHoleType.squares)
      const tkmHoles = this.cableHoles?.filter(cb => cb.sealType === SealHoleType.tkm)

      if (ukm1Holes && ukm1Holes.length > 0)
        items.push(new SummaryItem('Ucpávky', ukm1Holes[0].label, ukm1Holes.length))

      if (ukm2Holes && ukm2Holes.length > 0)
        items.push(new SummaryItem('Ucpávky', ukm2Holes[0].label, ukm2Holes.length))

      if (tkmHoles && tkmHoles.length > 0)
        items.push(new SummaryItem('Ucpávky', tkmHoles[0].label, tkmHoles.length))
    }

    items.push(new SummaryItem('Barva', this.colorName, undefined))

    if (this.fill?.toolGrid && this.standardCapacity)
      items.push(new SummaryItem('Kapacita skříně', `${this.standardCapacity} modulů`, undefined, 'Při maximálním osazení DIN lištami'))

    return items
  }
}

interface GroupedItemQuantity {
  label: string
  quantity: number
}
