<script lang="ts" setup>
import type { CaseConfig } from '~/types/CaseConfig'

const props = defineProps<{
  config?: CaseConfig
}>()
const { caseConfig } = storeToRefs(useCaseConfig())

const items = computed(() => {
  return props.config ? props.config.getSummaryItems() : caseConfig.value.getSummaryItems()
})
</script>

<template>
  <div
    v-for="(item, index) in items" :key="item.label + item.value" grid grid-cols-12 text-12px py-6px
    border-b="1px app-stroke" :class="{ 'border-none': index === items.length - 1 }" w-full
  >
    <p col-span-4>
      {{ item.label }}
    </p>
    <p :class="[item.quantity ? 'col-span-6' : 'col-span-8']">
      {{ item.value }}
    </p>
    <div v-if="item.description" text-app-red text-10px>
      {{ item.description }}
    </div>
    <p v-if="item.quantity" col-span-2 text-right pr-8px>
      ({{ item.quantity }}x)
    </p>
  </div>
</template>
