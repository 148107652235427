export const useAlert = defineStore(('alert'), () => {
  const activeAlerts = useState<Map<number, AlertType>>(() => new Map<number, AlertType>())

  const showAlert = async (alert: AlertType) => {
    const id = new Date().getTime()
    alert.id = id
    activeAlerts.value.set(id, alert)
  }

  const dismissAlert = (id: number) => activeAlerts.value.delete(id)

  return {
    activeAlerts: computed(() => [...activeAlerts.value.values()]),
    showAlert,
    dismissAlert,
  }
})

export interface AlertType {
  id?: number
  title: string
  content?: string
  seconds?: number
  intent: 'success' | 'info' | 'warning' | 'danger'
}
