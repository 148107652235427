export class Side {
  constructor (
    public label: string,
    public heights: number[],
  ) {}

  static fromJson (json: Record<string, any>) {
    return new Side(json.label, json.heights)
  }

  public toJson (): Record<string, any> {
    return {
      label: this.label,
      heights: this.heights,
    }
  }
}
