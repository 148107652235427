import payload_plugin_bjznHi4htw from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_encoding@0.1.13__firebase-_ykchh4bznlzi6ysbr5xmuzunwu/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_YsSFp61eIT from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OUbflYCWt1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LNjo9haOh8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_HV8DDIwixs from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_Zfu9Lk2TIa from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kykVzoMzxz from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bIt9HtvpOj from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nu8HyZOLto from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8ub7AaJ1yP from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_SK4lM6YOLV from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_mh46lzsohmvblodpcpdgfz6w5y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import plugin_client_qBxmegOlV7 from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_encoding@0.1.13__firebase-_ykchh4bznlzi6ysbr5xmuzunwu/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/vercel/path0/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/vercel/path0/.nuxt/vuefire-plugin.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import auto_animate_uTpJuTGsMC from "/vercel/path0/plugins/auto-animate.ts";
import final_modal_aCbWmDLqxD from "/vercel/path0/plugins/final-modal.ts";
import resizable_BtCGBtXSJh from "/vercel/path0/plugins/resizable.ts";
export default [
  payload_plugin_bjznHi4htw,
  revive_payload_client_YsSFp61eIT,
  unhead_OUbflYCWt1,
  router_LNjo9haOh8,
  sentry_client_HV8DDIwixs,
  payload_client_Zfu9Lk2TIa,
  navigation_repaint_client_kykVzoMzxz,
  check_outdated_build_client_bIt9HtvpOj,
  chunk_reload_client_nu8HyZOLto,
  plugin_vue3_8ub7AaJ1yP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SK4lM6YOLV,
  floating_vue_EIcJ7xiw0h,
  plugin_client_qBxmegOlV7,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  unocss_MzCDxu9LMj,
  auto_animate_uTpJuTGsMC,
  final_modal_aCbWmDLqxD,
  resizable_BtCGBtXSJh
]