<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import type { CartItem } from '~/types/CartItem'

const { closeCart, openOrder, openLogin } = useModals()
const { showAlert } = useAlert()

const { user } = storeToRefs(useUser())

const { cart, savedCarts } = storeToRefs(useCart())
const { archiveCart, restoreCart, removeItem } = useCart()

const { addCaseConfig } = useCaseConfig()

function editItem (item: CartItem): void {
  cart.value.removeItem(item)
  addCaseConfig(item.caseConfig)
  closeCart()

  showAlert({
    intent: 'info',
    title: 'Konfigurace byla načtena',
    content: 'Nyní můžete konfiguraci upravit a poté ji znovu přidat do košíku',
    seconds: 5,
  })
}

function order () {
  closeCart()
  if (user.value)
    openOrder()
  else
    openLogin()
}
</script>

<template>
  <VueFinalModal class="flex flex-col justify-center items-center" content-class="bg-white p-32px rounded-12px w-584px">
    <div flex justify-between items-start mb-32px>
      <h1 font-600 text-24px leading-24px>
        Vaše připravené konfigurace
      </h1>
      <UnoIcon i-heroicons-solid-x text="24px app-text-icon" cursor-pointer @click="closeCart()" />
    </div>
    <div v-if="savedCarts.length" flex flex-col items-center gap-8px pb-24px w-full>
      <div
        v-for="(savedCart, index) in savedCarts" :key="savedCart.savedDate.toString()" flex items-center
        justify-between gap-8px bg-app="green/20" rounded-xl px-12px py-8px text-14px w-fullSouhrn
      >
        <div>
          Uložený košík z <b>{{ formatDate(savedCart.savedDate, { time: true }) }}</b> - <button
            type="button" text-btn
            underline focus:outline-none @click="restoreCart(index)"
          >
            Obnovit (nahradí aktuální košík)
          </button>
        </div>
        <UnoIcon i-heroicons-solid-x text="app-text-dark 16px" cursor-pointer />
      </div>
    </div>
    <CaseConfigCard
      v-for="item of cart.items" :key="item.caseConfig.id" :case-config="item.caseConfig"
      :quantity="item.quantity || 0" :class="{ 'mb-16px': cart.isNotEmpty }"
      @quantity-change="(newQaunt) => item.quantity = newQaunt" @remove="removeItem(item)" @edit="editItem(item)"
    />
    <div v-if="cart.isNotEmpty" flex flex-col gap-8px w-full bg-app-bg-card p-16px rounded-lg>
      <div flex items-center justify-between>
        <h2>Cena Celkem:</h2>
        <h2>{{ formatPrice(cart.sum.cost) }} Kč bez DPH</h2>
      </div>
      <div flex items-center justify-between>
        <h2>DPH:</h2>
        <h2>{{ formatPrice(cart.sum.vat!) }} Kč</h2>
      </div>
      <div flex items-center justify-between border-t="app-stroke 1px" pt-8px>
        <h1 font-semibold>
          Součet:
        </h1>
        <h1 font-semibold>
          {{ formatPrice(cart.sum.total!) }} Kč včetně DPH
        </h1>
      </div>
    </div>
    <template v-if="cart.isEmpty">
      <h2 text-center text-app-text-dark>
        Košík neobsahuje žádné položky
      </h2>
    </template>
    <div v-if="cart.isNotEmpty" flex justify="end" items-center my-16px>
      <button text-btn text-app-red underline focus:outline-none type="button" @click="archiveCart()">
        Uložit košík na později
      </button>
    </div>
    <div flex justify-between items-center gap-24px mt-24px>
      <button text-btn text-app-text-dark underline focus:outline-none type="button" @click="closeCart()">
        Zavřít
      </button>
      <button v-if="cart.isNotEmpty" text-btn text-app-blue underline type="button" @click="order()">
        Přejí k objednávce
      </button>
    </div>
  </VueFinalModal>
</template>
