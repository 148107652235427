<script lang="ts" setup>
const props = defineProps<{
  label?: string
  placeholder?: string
  compact?: boolean
  suffix?: string
  modelValue?: { label: string, value: any }
  options: { label: string, value: any }[]
  resetValue?: { label: string, value: any }
  onlyShowSelectOnReset?: boolean
  fullWidth?: boolean
  optionSuffixIcon?: string
  optionsWithSuffix?: any[]
  lockOnInit?: boolean
  doNotLock?: boolean
  readonly?: boolean
}>()
const emits = defineEmits(['update:modelValue'])

const value = useVModel(props, 'modelValue', emits)

const [showSelect, toggleShowSelect] = useToggle(true)

watch(value, value => {
  if (props.doNotLock)
    return
  toggleShowSelect(!(value?.value))
}, {
  immediate: props.lockOnInit,
})

function onResetValue () {
  if (props.onlyShowSelectOnReset) {
    toggleShowSelect(true)
    return
  }
  value.value = props.resetValue
}

onMounted(() => {
  if (props.readonly)
    showSelect.value = false
})
</script>

<template>
  <div v-auto-animate flex items-center justify-between>
    <p v-if="label" text="app-text-dark 14px" leading-16px font-500 pr-12px>
      {{ label }}
    </p>
    <HeadlessListbox v-if="showSelect" v-model="value" as="div" :class="{ 'w-full': fullWidth }">
      <div relative>
        <HeadlessListboxButton
          :class="[fullWidth ? 'w-full' : 'max-w-200px']"
          relative flex items-center justify-between py-12px px-16px gap-12px border min-w-200px max-h-40px
          cursor-default rounded-3xl bg-white focus:ring-app-blue cursor-pointer
        >
          <span
            :class="[value ? 'text-black' : 'text-app-text-light', compact && value ? 'text-12px' : 'text-14px']" leading-16px
            truncate
          >{{ value?.label ?? placeholder }}</span>
          <div flex items-center gap-4px>
            <p v-if="suffix" text="14px app-text-light" leading-16px font-500 mb-2px>
              {{ suffix }}
            </p>
            <UnoIcon i-heroicons-solid-chevron-down text="20px app-icon-gray" aria-hidden="true" />
          </div>
        </HeadlessListboxButton>
        <transition
          leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <HeadlessListboxOptions
            absolute z-100 mt-4px w-full bg-white border shadow-lg rounded-2xl focus:outline-none
            divide-y max-h-400px overflow-y-auto un-scrollbar="~ w-5px track-color-app-stroke thumb-color-app-red rounded radius-10px"
          >
            <HeadlessListboxOption
              v-for="option in options" :key="option.value" v-slot="{ active, selected }" as="template"
              :value="option"
            >
              <li
                class="relative cursor-pointer select-none py-8px leading-16px focus:outline-none" :class="[active ? 'bg-app-bg-gray font-700' : 'font-400', compact ? 'text-12px px-12px' : 'text-14px px-16px', options.length > 6 ? 'first:rounded-tl-2xl last:rounded-bl-2xl' : 'first:rounded-t-2xl last:rounded-b-2xl']"
              >
                <div class="flex items-center justify-between">
                  <span class="block truncate" :class="[selected ? 'font-700' : 'font-400']">{{ option.label }}</span>
                  <span v-if="optionsWithSuffix?.includes(option)">{{ optionSuffixIcon ?? 'nic' }}</span>
                </div>
              </li>
            </HeadlessListboxOption>
          </HeadlessListboxOptions>
        </transition>
      </div>
    </HeadlessListbox>
    <div v-else flex items-center justify-between w-200px px-4px h-40px>
      <span text="14px black" leading-16px font-700 max-w-120px>
        <span max-w-100px truncate>{{ value!.label }}</span>
        <span ml-4px>{{ suffix }}</span>
      </span>
      <UnoIcon v-if="!readonly" i-heroicons-solid-x-circle text="24px app-text-icon" cursor-pointer @click="onResetValue()" />
    </div>
  </div>
</template>
