import { User, UserRole } from '~/types/User'

export const useUser = defineStore('user', () => {
  const user = useState<User | null>(() => null)

  const { fetchSaleGroup } = useSale()

  const fetchUserData = async (uid: string) => {
    try {
      const userData = await $fetch(`/api/user/${uid}`)
      if (!userData)
        console.error('Failed to fetch user')

      user.value = User.fromJson(superjsonParse<User>(userData))

      const { hasUserPassword } = useAuth()

      user.value!.hasPassword = (await hasUserPassword(user.value!.email)).hasPassword

      fetchSaleGroup(user.value.idCompany)
    }
    catch (error) {
      console.error(error)
    }

    return true
  }

  return {
    user,
    isAdmin: computed(() => user.value?.roles.includes(UserRole.admin)),
    fetchUserData,
  }
})
