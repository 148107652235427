import type { CaseType } from '~/types/CaseType'
import { CaseConfig } from '~/types/CaseConfig'
import { ElectricityPrepare, EmptyCase, Fill, MountPanel, None, NoneOnlyDoors, ToolGrid, ToolGridContent } from '~/types/Fill'

export const useCaseConfig = defineStore('caseConfig', () => {
  const caseConfig = useState<CaseConfig>('caseConfig', () => new CaseConfig())
  const allConfigs = useState<CaseConfig[]>('allConfigs', () => [])

  const configsInitialized = ref(false)

  const { activeTabIndex } = storeToRefs(useTabs())
  const { initTabs, createTab, removeTab } = useTabs()

  const { masks } = storeToRefs(useRemoteConfig())

  function setCaseType (caseType?: CaseType) {
    caseConfig.value.caseType = caseType
  }

  function changeFill (id: number) {
    let fill: Fill

    caseConfig.value.ventilations = []

    if (id === Fill.toolGrid) {
      fill = new ToolGrid()

      if (!caseConfig.value.caseInfo?.orderNumber)
        fill.asToolGrid.content = ToolGridContent.onlyPrepare
      else
        fill.asToolGrid.content = ToolGridContent.full
    }
    else if (id === Fill.mountPanel) {
      fill = new MountPanel()
    }
    else if (id === Fill.emptyCase) {
      fill = new EmptyCase()
    }
    else if (id === Fill.noneOnlyDoors) {
      fill = new NoneOnlyDoors()
    }
    else if (id === Fill.electricityPrepare) {
      fill = new ElectricityPrepare()
      fill.asElectricity.prepareElectricity(caseConfig.value.caseInfo!, masks.value)
    }
    else {
      fill = new None()
    }

    console.log(fill)
    caseConfig.value.fill = fill
  }

  const addCaseConfig = async (cc?: CaseConfig) => {
    if (!cc) {
      const generatedId = await $fetch('/api/case-config-id')

      if (generatedId) {
        const newCaseConfig = new CaseConfig(generatedId)
        allConfigs.value.push(newCaseConfig)
        caseConfig.value = newCaseConfig
        createTab(newCaseConfig)
      }
      return
    }

    allConfigs.value.push(cc!)
    caseConfig.value = cc!
    createTab(cc!)
  }

  const removeCaseConfig = (configId: string) => {
    const removedConfigIndex = allConfigs.value.indexOf(allConfigs.value.find(config => config.id === configId)!)
    allConfigs.value.splice(removedConfigIndex, 1)

    removeTab(configId)
  }

  watch(activeTabIndex, () => {
    caseConfig.value = allConfigs.value[activeTabIndex.value]
  })

  const initializeCaseConfigs = async () => {
    const generatedId = await $fetch<string>('/api/case-config-id')
    if (generatedId)
      caseConfig.value.id = generatedId
    const localStorageCases = localStorage.getItem('cases_v2')

    if (localStorageCases)
      Object.values<Record<string, any>>(JSON.parse(localStorageCases)).forEach(data => allConfigs.value.push(CaseConfig.fromJson(data)))

    if (allConfigs.value.length > 0)
      caseConfig.value = allConfigs.value[0]
    else
      allConfigs.value.push(caseConfig.value)

    window.addEventListener('unload', () => {
      const serializedCases: Record<string, any> = {}
      allConfigs.value.forEach(config => {
        serializedCases[config.id!] = config.toJson()
      })
      localStorage.setItem('cases_v2', JSON.stringify(serializedCases))
    })
    configsInitialized.value = true

    initTabs(allConfigs.value)
  }

  return {
    allConfigs,
    caseConfig,
    configsInitialized,
    addCaseConfig,
    removeCaseConfig,
    setCaseType,
    changeFill,
    initializeCaseConfigs,
  }
})
