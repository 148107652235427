import { Cart } from '~/types/Cart'
import type { CartItem } from '~/types/CartItem'
import { CaseConfig } from '~/types/CaseConfig'

export const useCart = defineStore('cart', () => {
  const { openCart } = useModals()
  const { showAlert } = useAlert()

  const cart = ref<Cart>(new Cart([], new Date()))

  const savedCarts = ref<Cart[]>([])

  const addToCart = (caseConfig: CaseConfig) => {
    const cc = CaseConfig.fromJson(caseConfig.toJson())
    cart.value.addCaseConfig(cc)

    showAlert({
      intent: 'success',
      title: 'Položka byla přidána do košíku',
      seconds: 5,
    })
    openCart()
  }

  const archiveCart = () => {
    cart.value.savedDate = new Date()
    savedCarts.value.push(cart.value)
    cart.value = new Cart([], new Date())
    showAlert({
      intent: 'success',
      title: 'Košík byl uložen',
      seconds: 5,
    })
  }

  const restoreCart = (savedCartIndex: number) => {
    cart.value = savedCarts.value[savedCartIndex]
    savedCarts.value.splice(savedCartIndex, 1)
    showAlert({
      intent: 'success',
      title: 'Košík byl nahrazen',
      seconds: 5,
    })
  }

  const removeItem = (item: CartItem) => {
    cart.value.removeItem(item)
    showAlert({
      intent: 'success',
      title: 'Položka byla odebrána',
      seconds: 5,
    })
  }

  return {
    cart,
    savedCarts,
    addToCart,
    archiveCart,
    restoreCart,
    removeItem,
  }
})
