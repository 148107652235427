<script lang="ts" setup>
const url = useRequestURL()
const { signIn } = useAuth()
const { openPassword } = useModals()

const { activeAlerts } = storeToRefs(useAlert())

const { fetchSaleGroup } = useSale()

const { initializeCaseConfigs } = useCaseConfig()

onMounted(async () => {
  if (url.search.includes('sign-email-link')) {
    const regex = /sign-email-link=([^&]+)/
    const match = regex.exec(url.search)
    const email = match ? match[1] : ''

    if (!email)
      return
    const credentials = {
      email,
      link: url.href,
    }
    await signIn(credentials)
    openPassword()
  }

  await fetchSaleGroup()
})

await initializeCaseConfigs()
</script>

<template>
  <div relative h-full>
    <NuxtLayout />
    <div v-auto-animate absolute top-96px right-32px flex flex-col gap-16px z-1001>
      <Alert v-for="alert of activeAlerts" :key="alert.id" :alert="alert" />
    </div>
  </div>
</template>

<style>
html,
body,
#__nuxt {
  font-family: 'Inter';
  height: 100%;
  @apply bg-white whitespace-nowrap;
}

*:focus {
  outline: 2px solid #006FFF;
  outline-offset: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
