import type { CaseConfig } from './CaseConfig'

export class CartItem {
  constructor (
    public quantity: number,
    public caseConfig: CaseConfig,
    public creationDate: Date,
  ) { }

  static fromJson (json: Record<string, any>): CartItem {
    return new CartItem(
      json.quantity, json.case_config, json.creation_date,
    )
  }

  public toJson (): Record<string, any> {
    return {
      quantity: this.quantity,
      case_config: this.caseConfig,
      creation_date: this.creationDate,
    }
  }

  public get sum (): number {
    return this.caseConfig.casePrice * this.quantity
  }
}
