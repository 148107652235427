export class Mask {
  constructor (
    public type: MaskType,
    public label: string,
    public height: number,
    public fill: number[],
  ) { }

  static fromJson (json: Record<string, any>): Mask {
    return new Mask(
      json.type,
      json.label,
      json.height,
      json.fill,
    )
  }

  public toJson (): Record<string, any> {
    return {
      type: this.type,
      label: this.label,
      height: this.height,
      fill: this.fill,
    }
  }

  public clone (): Mask {
    return new Mask(this.type, this.label, this.height, this.fill)
  }
}

export enum MaskType {
  cutOut = 1,
  filled = 2,
}
