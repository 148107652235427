import { Sale } from '~/types/Sale'

export class SaleGroup {
  public usageCounter: number
  public updatedAt: Date

  constructor (
    public id: string,
    public title: string,
    public sales: Sale[],
    updatedAt?: Date,
    usageCounter?: number,
  ) {
    this.usageCounter = usageCounter || 0
    this.updatedAt = updatedAt || new Date()
  }

  static fromJson (json: Record<string, any>): SaleGroup {
    return new SaleGroup(
      json.id,
      json.title,
      json.sales.map((s: Record<string, any>) => Sale.fromJson(s)),
      json.updated_at,
      json.usage_counter,
    )
  }

  public toJson (): Record<string, any> {
    return {
      id: this.id,
      title: this.title,
      sales: this.sales.map(s => s.toJson()),
      usage_counter: this.usageCounter,
      updated_at: this.updatedAt,
    }
  }
}
